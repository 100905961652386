<template>
  <div class="container">
    <div class="row">
      <div class="col-md-4 shadow-none mx-auto pt-2 rounded">
        <div class="card shadow">
          <div class="card-body text-center">
            <i
              class="fa fa-sticky-note-o fa-2"
              aria-hidden="true"
            />
            <div class="signin-label mb-4">
              Generate Bill
            </div>

            <b-form @submit.stop.prevent="onSubmit">
              <b-form-group id="input-group-1">
                <b-form-input
                  id="emp_num"
                  v-model="$v.form.emp_num.$model"
                  size="sm"
                  name="emp_num"
                  type="text"
                  :state="validateState('emp_num')"
                  placeholder="User Employee Number"
                  aria-describedby="emp_num-live-feedback"
                />

                <b-form-invalid-feedback
                  id="emp_num-live-feedback"
                >
                  User ID validation failed.
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="text-center">
                <b-button
                  pill
                  type="submit"
                  variant="success"
                  size="md"
                  class="mt-3"
                  :disabled="$v.$invalid"
                >
                  Proceed <i class="fa fa-arrow-circle-right" />
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiService } from "@/services";
import { mapActions } from 'vuex';
import { validationMixin } from "vuelidate"; 
import { required } from "vuelidate/lib/validators";
import router from '@/router';
export default {
  name: 'Biller',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        emp_num: '',
        submitted: false
      }
    }
  },
  validations: {
    form: {
      emp_num: { 
        required,
        async isUnique (value) {
          if (value === '') return true;
          if( value.length > 2 ){
            const response = await apiService.postAll('/exists/user', { 
              emp_num: value 
            }).catch(error => this.showError(error));
            if(response){
              return Boolean(await response.data);
            }else{
              return false;
            }
          }else{
            return false;
          }
        } 
      },
    }
  },
  methods: {
    ...mapActions({ showError: 'error/show' }),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }else{
        router.push({ name: 'UserMeters', params: { id: this.form.emp_num } });
      }
    },
  },
};
</script>



<style scoped>
	.signin-label{ font-size: 1.2rem; color: #656262; }
	.remember-me-label{ font-size: 0.8rem; vertical-align: middle; color: #656262; }
	.forgot-password{ font-size: 0.8rem; color: #00b7c2; }
	.sign-up-label{ font-size: 0.8rem; color: #0f4c75; }
	.input-label{ font-size: 14px; color: #656262; }
	.fa-2{ font-size: 6rem; }
</style>